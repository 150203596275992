import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistReducer } from 'redux-persist'

import UserReducer from './Users/Slice'
import PostReducer from './Posts/Slice'


const persistConfig = {
    key: 'soccer',
    storage,
}

export const store = configureStore({
    reducer: persistReducer(persistConfig, combineReducers({
        user: UserReducer,
        post: PostReducer,
    })),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch