import './App.css';
import Main from './MainPage/Main';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './LoginPage/Login';
import Register from './Components/RegisterPage/Register';
import Confirm from './ConfirmPage/Confirm';
import NotFound from './NotFound.js/NotFound';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { PostActions } from './Redux/Posts/Actions';
import Blog from './Blog/Blog';


function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    const data = new FormData()
    data.append("post_text", "test 123")
    data.append("user_id", "1")
    // data.append("file")
    
    // dispatch(PostActions.readPost( { arguments: {} } ))
  }, [])

  return (
    <div className="w-full overflow-x-hidden">
      {/* <NavBar /> */}
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login/>} />
          {/* <Route path="/register" element={<Register/>} /> */}
          <Route path="/register/confirm" element={<Confirm/>} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
