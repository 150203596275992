import React, { useState } from 'react'
import {IoIosArrowBack , IoIosArrowForward} from 'react-icons/io'
import { useWindowSize } from '../Functions'
import ImageSlider from './ImageSlider'

const Brokering = () => {

  const [size, _] = useWindowSize()
  const images = ["https://legendsportmanagement.com/slider1.jpeg" , "https://legendsportmanagement.com/slider2.jpg" , "https://legendsportmanagement.com/slider1.jpeg"]
 
  return (
    <div className='brokering mb-10 lg:flex lg:flex-col lg:justify-center lg:items-center overflow-x-hidden w-full'>
      <p className='font-poppins p-2 text-justify lg:max-w-[1200px]  lg:text-justify lg:text-lg'>We are experienced negotiators with extensive knowledge of football clubs and sponsors, and we specialize in brokering successful transfers for football players.
</p>
      <p className='font-poppins p-2 text-justify lg:max-w-[1200px]  lg:text-justify lg:text-lg'>At Legends Sport, we understand that transferring between clubs is a critical moment in a player's career, and that's why we provide our clients with expert guidance and representation every step of the way. Our licensed agents have the knowledge and experience necessary to ensure that you get the most out of your transfer and that your interests are always protected.</p>
      <p className='font-poppins p-2 text-justify lg:max-w-[1200px]  lg:text-justify lg:text-lg'>We work tirelessly to negotiate on your behalf, ensuring that you receive the best possible deal while taking into account the other party's interests as well. With our extensive network and in-depth understanding of the transfer process, we have successfully brokered transfers for numerous football players with outstanding results.</p>
      <p className='font-poppins p-2 text-justify lg:max-w-[1200px]  lg:text-justify lg:text-lg'>At Legends Sport, we are committed to providing our clients with the highest level of service and support. We understand the importance of having a reliable and trustworthy intermediary on your side, and we take pride in helping our clients achieve their goals and succeed in their careers.</p>
      <p className='font-poppins p-2 text-justify lg:max-w-[1200px]  lg:text-justify lg:text-lg'>Trust Legends Sport to be your partner in sports brokering, and let us help you navigate the complex world of football transfers with confidence and ease.</p>
      <div className='flex flex-row gap-2 justify-center items-center lg:justify-around '>
        {size.width < 1000 ? 
        <ImageSlider />
        :
        <div className='flex flex-row gap-2 w-full justify-center p-10 bg-neutral-100 '>
            {images.map(img => <img className='w-1/5 shadow-md' src={img} />)}
        </div>
        
      }
      </div>
    </div>
  )
}

export default Brokering
