import { AiOutlineLoading } from "react-icons/ai"

const Loading:React.FC<{
    isOpen: true | false
    message: string
}> =({ isOpen, message}) => {
    return isOpen ? (
        <div className='w-[100vw] h-[120vw] fixed top-0 left-0 bg-slate-900 opacity-30'>
            <div className='w-[100vw] h-[100vh] flex items-center justify-center'>
                <div className='flex items-center bg-white w-[300px] rounded-md p-4'>
                    <AiOutlineLoading className="w-2/12 animate-spin h-8 w-8 mr-3 text-emerald-500 !opacity-100" />
                    <p className='w-10/12'>{message}</p>
                </div>
            </div>
        </div>
    ) : null
}

export default Loading