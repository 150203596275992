import React, { useState } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
import { AiFillPropertySafety, AiOutlineClose } from 'react-icons/ai'
import NavMenu from './NavMenu'
import Cards from '../Components/Cards'
import { useWindowSize } from '../Functions'
// import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PostModal from '../Blog/PostModal'
import {Link, NavLink} from 'react-router-dom'
import { UserActions } from '../Redux/Users/Actions'
import { logout } from '../Redux/Users/Slice'
import { MdArrowBackIosNew } from 'react-icons/md'


const NavigationBlog = (props) => {
  const user_login_info = useSelector(state => state.user.user)
  const dispatch = useDispatch()
  const handleLogout = (e) => {
    e.preventDefault()
    dispatch(logout())
   
  }
 
  

  
  const [postModal , setPostModal] = useState(false)
  const [expandedMenu, setExpandedMenu] = useState(false)
  const [size, _] = useWindowSize()
  const [tokenS , setTokenS] = useState(user_login_info.token)
  const [clicked , setClicked] = useState("")
  const clickedClass = "bg-transparent  hover:rounded-sm hover:scale-110 transition-all text-center p-4 text-xl underline underline-offset-8"
  const regClass = "bg-transparent  hover:rounded-sm hover:scale-110 transition-all text-center p-4 text-xl "

  return (
    <div className=' w-full absolute top-0 z-20 overflow-x-hidden bg-neutral-900 '  >
      <div className='bg- w-full p-6 text-white font-poppins flex flex-row items-center justify-between '>
      <NavLink to="/">
      <div className='flex flex-row items-center gap-3 hover:scale-110 transition-all'>
      <MdArrowBackIosNew />
      <h1 className='font-poppins '>back </h1>
      </div>
      </NavLink>
        <div className='flex flex-row gap-4  items-center'>
        </div>
        
         
         
     
          <div className='flex flex-row gap-2 mr-4'>
           
            
           {user_login_info.token != "" ?
           <h1 onClick={() => setPostModal(true)} className='bg-neutral-100 text-slate-900 pl-8 pr-8 text-center p-2 hover:bg-slate-300 rounded-full text-lg cursor-pointer '>New post</h1>
           :
           <Link to="/login" ><h1 className=' bg-neutral-100 text-slate-900 pl-8 pr-8 text-center p-2 hover:bg-slate-300 rounded-full text-lg '>Login</h1></Link>}
          </div>  {expandedMenu === true ? null : null}
          {postModal === true ? <PostModal setPostModal={setPostModal}/> : null}
      </div>

      {/* {expandedMenu === true && size.width < 1000 ? <NavMenu /> : null} */}

    </div>
  )
}

export default NavigationBlog
