import React from 'react'
import { CiLinkedin, CiLocationOn } from 'react-icons/ci'
import {BsFacebook, BsTelephone } from 'react-icons/bs'
import { CiMail , CiClock1 } from 'react-icons/ci'
import { AiFillInstagram, AiFillTwitterCircle, AiFillLinkedin} from 'react-icons/ai'
import { useWindowSize } from '../Functions'
import { useSelector } from 'react-redux'

const Contact = () => {
    const [size , _] = useWindowSize()
    return (
        <div className='mb-5 p-10 w-full sm:w-1/2  flex flex-col  lg:justify-center overflow-x-hidden '>
            <div className='w-full flex flex-col gap-6  lg:w-1/2 lg:justify-center lg:mx-auto'>
            <div className='flex flex-row  items-center gap-2 lg:ml-[4.8rem]  lg:w-[50vh] '>
                <CiLocationOn className='text-white text-3xl lg:text-[2.7rem]' />
                <h1 className='font-raleway  text-white  text-[0.8rem] lg:text-[1.2rem]'>Filip Vtori Makednoski No.14 Bitola, Macedonia</h1>
            </div>
            <div className='flex flex-row items-center gap-2 ml-1 lg:ml-[5.1rem] lg:w-[50vh]'>
                <BsTelephone className='text-white text-2xl lg:text-[1.8rem]' />
                <h1 className='font-raleway text-white  text-[0.8rem] lg:text-[1.2rem]'>+38970373252</h1>
            </div>
            <div className='flex flex-row items-center gap-2 ml-1 lg:ml-[5rem] lg:w-[50vh]'>
                <CiMail className='text-white text-3xl lg:text-[2.3rem]' />
                <h1 className='font-raleway text-white  text-[0.8rem] lg:text-[1.2rem] '>info@legendsportmanagement.com</h1>
            </div>
            <div className='flex flex-row items-center gap-2 ml-1 lg:ml-[5rem] lg:w-[50vh]'>
                <CiClock1 className='text-white text-3xl lg:text-[2.2rem]' />
                <h1 className='font-raleway text-white  text-[0.8rem] lg:text-[1.2rem]'>MON - FRI : 10AM - 6PM</h1>
            </div>
            </div>
            <div className='flex flex-row justify-center gap-8 lg:gap-12 items-center p-10 lg:mt-8'>
                <BsFacebook size={30} className='text-slate-300 hover:text-slate-300 cursor-not-allowed'/>
                <AiFillTwitterCircle className='text-slate-300 hover:text-slate-300 cursor-not-allowed' size={35} />
                <AiFillInstagram className='text-slate-300 hover:text-slate-300 cursor-not-allowed' size={36}/>
                <a href="https://www.linkedin.com/company/legends-sport/about/"><AiFillLinkedin className='text-white hover:text-slate-300' size={36} /></a>
            </div>
        </div>
    )
}

export default Contact
