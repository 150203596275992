import React, { useState } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
import { AiFillPropertySafety, AiOutlineClose } from 'react-icons/ai'
import NavMenu from './NavMenu'
import Cards from '../Components/Cards'
import { useWindowSize } from '../Functions'
// import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PostModal from '../Blog/PostModal'
import {Link, NavLink} from 'react-router-dom'
import { UserActions } from '../Redux/Users/Actions'
import { logout } from '../Redux/Users/Slice'


const Navigation = (props) => {
  const user_login_info = useSelector(state => state.user.user)
  const dispatch = useDispatch()
  const handleLogout = (e) => {
    e.preventDefault()
    dispatch(logout())
   
  }
 
  

  {window.addEventListener("scroll" , e => {
    if(window.scrollY < 100){
      setClicked("")
    }
  })}
  const [postModal , setPostModal] = useState(false)
  const [expandedMenu, setExpandedMenu] = useState(false)
  const [size, _] = useWindowSize()
  const [tokenS , setTokenS] = useState(user_login_info.token)
  const [clicked , setClicked] = useState("")
  const clickedClass = "bg-transparent cursor-pointer hover:rounded-sm hover:scale-110 transition-all text-center p-4 text-xl underline underline-offset-8"
  const regClass = "bg-transparent cursor-pointer  hover:rounded-sm hover:scale-110 transition-all text-center p-4 text-xl "

  return (
    <div className=' w-full absolute top-0 z-20 overflow-x-hidden '  >
      <div className='bg- w-full p-6 text-white font-poppins flex flex-row items-center justify-between '>
        <div className='flex flex-row gap-4  items-center'>
        </div>
        
         {size.width > 1000 ? null : expandedMenu === true ?
        <AiOutlineClose onClick={() => setExpandedMenu(false)} size={25}/>
        :
        <RxHamburgerMenu onClick={() => setExpandedMenu(true)} className='transition-all' size={30}/>}
         
        {size.width > 1000 ?
          <div className='flex flex-row gap-2 mr-4 items-center'>
           <Link to="/" > <h1  className='  bg-transparent  hover:rounded-sm hover:scale-110 transition-all text-center p-4 text-xl '>Home</h1></Link>
            <h1 onClick={() => {setClicked("services") ;    document.getElementById("services").scrollIntoView({behavior : 'smooth'}); }}   className={clicked === "services" ? clickedClass : regClass}>Services</h1>
            <h1 onClick={() => {setClicked("scouting") ; ;  document.getElementById("scouting").scrollIntoView({behavior : 'smooth'})}}   className={clicked === "scouting" ? clickedClass : regClass}>Scouting</h1>
            <h1 onClick={() => {setClicked("brokerage") ; ; document.getElementById("scouting").scrollIntoView({behavior : 'smooth'})}} className={clicked === "brokerage" ? clickedClass : regClass}>Brokerage</h1>
            <h1 onClick={() => {setClicked("marketing") ; ; document.getElementById("marketing").scrollIntoView({behavior : 'smooth'})}} className={clicked === "marketing" ? clickedClass : regClass} >Marketing</h1>
            <h1  onClick={() => {setClicked("contact") ; ;  document.getElementById("contact").scrollIntoView({behavior : 'smooth'})}}  className={clicked === "contact" ? clickedClass : regClass}>Contact</h1>
            <Link to="/blog" ><h1 className='bg-transparent  hover:rounded-sm hover:scale-110 transition-all text-center p-4 text-xl '>Blog</h1></Link>
            {user_login_info.token != "" ? <button className=' bg-neutral-100 text-slate-900 pl-8 pr-8 text-center p-2 hover:bg-slate-300 rounded-full text-lg ' onClick={e => handleLogout(e)}>Logout</button>
            :
            <NavLink to="/login"><button className=' bg-neutral-100 text-slate-900 pl-8 pr-8 text-center p-2 hover:bg-slate-300 rounded-full text-lg ' >Login</button></NavLink>}
            
          </div> : expandedMenu === true ? <NavMenu setExpandedMenu={setExpandedMenu}  /> : null}
          {postModal === true ? <PostModal setPostModal={setPostModal}/> : null}
      </div>

      {/* {expandedMenu === true && size.width < 1000 ? <NavMenu /> : null} */}

    </div>
  )
}

export default Navigation
