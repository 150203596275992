import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Brokering from '../Components/Brokering'
import Cards from '../Components/Cards'
import Contact from '../Components/Contact'
import Marketing from '../Components/Marketing'
import Scouting from '../Components/Scouting'
import Services from '../Components/Services'
import { useWindowSize } from '../Functions'
import Navigation from '../Navigation/Navigation'
import { PostActions } from '../Redux/Posts/Actions'
import Footer from './Footer'
import LogoFixed from './LogoFixed'

const Main = () => {
    const [size, _] = useWindowSize()
    const ref = useRef()
    const [scroll, setScroll] = useState(ref)
    console.log(ref)
    const dispatch = useDispatch()
    ///PROVERKA NA REDUX VO POSTS I KAKO GI POVIKUVAME VO UI

    return (
        <div className='overflow-x-hidden lg:h-screen bg-neutral-100 lg:w-full'>
            <Navigation scroll={scroll} setScroll={setScroll} ref={ref} />
            {size.width < 1100 ? <h1 className='animate-pulse text-white font-poppins overflow-x-hidden absolute top-[1.5rem] right-0  w-full  gap-1  flex flex-row  items-center justify-center'>Legends sport agency</h1> : <LogoFixed />}

            <div className='overflow-hidden w-screen  h-full bg-fit'>
                {/*<video x autoPlay muted>
                    <source className='w-full h-full' src="https://www.legendsportmanagement.com/kocheBGvideo.mp4" type="video/mp4" />
                </video>*/}
                <img src="/pxfuel.jpg" className="w-full h-full" />
            </div>
            <div ref={ref} className=' absolute z-0 w-full bg-neutral-100 '>
                <div id='services' className='flex flex-col items-center'>
                    <h1 className='text-slate-800 font-poppins  text-center text-xl p-6 lg:text-2xl' >SERVICES</h1>
                    <hr className='w-2/3 h-px bg-neutral-300 border-0' />
                    <Services className="services" />
                </div>
                <div id="scouting" ref={ref} className=' flex flex-col items-center bg-neutral-100'>
                    <h1 className='text-slate-900 font-poppins  text-center text-xl p-6 lg:text-2xl' >SCOUTING</h1>
                    <hr className='w-2/3 h-px bg-neutral-300 border-0' />
                    <Scouting />
                </div>
                <div id="brokerage" ref={ref} className=' flex flex-col items-center bg-neutral-100'>
                    <h1 className='text-slate-900 font-poppins  text-center text-xl p-6 lg:text-2xl' >BROKERING</h1>
                    <hr className='w-2/3 h-px bg-neutral-300 border-0' />
                    <Brokering />
                </div>
                <div id='marketing' ref={ref} className=' flex flex-col items-center bg-neutral-100'>
                    <h1 className='text-slate-900 font-poppins  text-center text-xl p-6 lg:text-2xl' >MARKETING</h1>
                    <hr className='w-2/3 h-px bg-neutral-300 border-0' />
                    <Marketing />
                </div>
                <div id='contact' className='contact flex flex-col items-center bg-neutral-900 text-white lg:pb-[5rem] '>
                    <h1 className=' font-poppins  text-center text-xl lg:text-2xl p-6' >GET IN TOUCH</h1>
                    <div className='lg:divide-x-2   flex flex-col justify-center items-center lg:flex lg:flex-row lg:gap-2 w-full overflow-x-hidden'>
                        {size.width < 1000 ?
                            <div className='flex flex-col justify-center items-center w-1/2 m-4'>
                                <div className='flex flex-col gap-2'>
                                    <div className='font-raleway font-semibold text-white flex flex-col'>
                                        <div class="mapouter">
                                            <div class="gmap_canvas">
                                                {/* <iframe width="340" height="280" id="gmap_canvas" src="https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1sskopje!6i13">
                                                </iframe> */}
                                                <iframe 
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1789.5570012215521!2d21.336001631002137!3d41.03260344997053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135724fee59fc1e5%3A0x94439a86b200f453!2sFilip%20II%20Makedonski%2014%2C%20Bitola!5e0!3m2!1sen!2smk!4v1678735790817!5m2!1sen!2smk"
                                                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="340" height="280" id="gmap_canvas"
                                                ></iframe>
                                            </div></div>
                                    </div>

                                </div>
                            </div> :
                            <div id='contact' className=' flex flex-col justify-center items-center w-1/2 m-4 p-4 pb-[rem]'>
                                <div className='flex flex-col gap-2'>
                                    <div className='font-raleway font-semibold text-white flex flex-col'>
                                        <div class="mapouter">
                                            <div class="gmap_canvas">
                                            <iframe 
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1789.5570012215521!2d21.336001631002137!3d41.03260344997053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135724fee59fc1e5%3A0x94439a86b200f453!2sFilip%20II%20Makedonski%2014%2C%20Bitola!5e0!3m2!1sen!2smk!4v1678735790817!5m2!1sen!2smk"
                                                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="700" height="400" id="gmap_canvas"
                                                ></iframe></div></div>
                                    </div>

                                </div>
                            </div>
                        }
                        <Contact />
                    </div>
                </div>
                <Footer />

            </div>



        </div>
    )
}

export default Main
