import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useWindowSize } from '../Functions'
import Footer from '../MainPage/Footer'
import Navigation from '../Navigation/Navigation'
import NavigationBlog from '../Navigation/NavigationBlog'
import { PostActions } from '../Redux/Posts/Actions'
import BlogDetailsModal from './BlogDetailsModal'
import DeleteModal from './DeleteModal'
import Loading from '../Common/Loading'

const Blog = () => {
    const [blogDetails, setBlogDetails] = useState(false)
    const [clickedPost, setClickedPost] = useState()
    const [deleteModal , setDeleteModal] = useState(false)
    const [clickedId , setClickedId] = useState()
    const [size, _] = useWindowSize()
    const dispatch = useDispatch()
    const posts = useSelector(state => state.post)
    const post_data = posts.posts.posts
    useEffect(() => {
        dispatch(PostActions.readPost({ arguments: {} }))
    }, [])
    console.log(clickedId)
    const handleDelete = (e) =>{
        e.preventDefault()
        const data = new FormData()
        data.append("id", clickedId)
        data.append("archived", 1)
        dispatch(PostActions.updatePost({ post: data }))
        // post_data.filter(p => p.id !== clickedId)
    }
    console.log(posts.posts.readStatus === "pending")
 

    const tabletDesktopClass = 'flex flex-row  gap-2 bg-white text-slate-800 font-poppins  lg:w-3/5 w-full mx-auto   rounded-md overflow-hidden shadow-md md:h-[35vh] lg:h-[40vh] '
    const mobileClass = 'flex flex-col  gap-2 m-2    bg-white text-slate-800 font-poppins  lg:w-3/5 mx-auto md:w-full  rounded overflow-hidden shadow-md h-1/2 '
    return (
        <div className='flex flex-col bg-slate-900 h-screen '>
            <Loading isOpen={posts.posts.readStatus === "pending"} message={"Loading posts..."} />
            <NavigationBlog />
            <div className='pt-24 w-screen   gap-1  h-full mb-6 overflow-y-scroll bg-slate-200 min-h-screen'>
                <div className=' w-full flex flex-col p-1  md:p-10 md:grid md:grid-cols-1 md:gap-4 '>
                    {post_data.filter(p => p.archived != 1).length === 0 ? <h1 className="text-center font-poppins my-4 text-slate-800 font-bold">There are no posts yet.</h1> : null}
                    {post_data.map(post => post.archived === 1 ? null : 
                        <div  className={size.width < 600 ? mobileClass : tabletDesktopClass}>
                            <img className='sm:w-1/3 h-1/2 w-full md:h-full   lg:h-full ' src={"https://static.legendsportmanagement.com/" + post.images[0]?.path} />

                            <div className='flex flex-col md:justify-center'>
                            <h1 className='font-poppins text-justify p-2 md:p- md:text-[0.75rem] lg:text-[0.85rem] text-[0.8rem]'>{post.post_text}</h1>
                            <div className='flex flex-col justify-center items-center gap-2'>
                               <h1 onClick={() => { setBlogDetails(true); setClickedPost(post.id) }} className='text-center p-4 underline cursor-pointer md:text-sm md:p-1'>see images</h1>
                               <button onClick={() => {setDeleteModal(true) ; setClickedId(post.id)}} className='font-poppins text-white bg-red-500 pr-4 pl-4 p-2 transition-all hover:scale-110 rounded-md shadow-md mb-2'>delete</button>
                            </div>
                            </div>
                        </div>



                    )}

                </div>

            </div>
            {deleteModal === true ? <DeleteModal setDeleteModal={setDeleteModal} handleDelete={handleDelete} /> : null}
            {blogDetails === true ? <BlogDetailsModal clickedPost={clickedPost} setBlogDetails={setBlogDetails} /> : null}
            <Footer />
        </div>
    )
}

export default Blog
