import { createSlice } from "@reduxjs/toolkit";
import { PostReducers } from "./Actions";
import { PostState } from "./Interface";


const init: PostState = {
    posts : {
        posts : [],
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
    }
}

const PostSlice = createSlice({
    name: "post",
    initialState: init,
    reducers: {

    },
    extraReducers(builder) {
        for (const action in PostReducers) {
            builder.addCase(action, PostReducers[action])
        }
    }
})

export default PostSlice.reducer