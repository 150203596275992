import React , {useState} from 'react'
import {IoIosArrowBack , IoIosArrowForward} from 'react-icons/io'

const ImageSlider = () => {
    const [imageSlider, setImageSlider] = useState(1)
    const images = ["https://legendsportmanagement.com/slider3.jpg", "https://legendsportmanagement.com/slider4.jpg", "https://legendsportmanagement.com/slider3.jpg"]
  return (
    <div className='flex flex-row gap-2 justify-around items-center overflow-x-hidden bg-neutral-100'>
        {imageSlider === 0 ? <IoIosArrowBack className='lg:cursor-not-allowed  text-red-500 text-3xl lg:text-[4rem] lg:bg-slate-300 lg:rounded-full lg:hover:bg-slate-200' /> : <IoIosArrowBack className='text-slate-900 text-3xl lg:text-[4rem] lg:bg-slate-300 lg:rounded-full lg:hover:bg-slate-200' onClick={() => setImageSlider(imageSlider - 1)} />}
        <img className='w-2/3 lg:w-1/3' src={images[imageSlider]} />
        {imageSlider === images.length - 1 ? <IoIosArrowForward className='lg:cursor-not-allowed text-red-500 text-3xl lg:text-[4rem] lg:bg-slate-300 lg:rounded-full lg:hover:bg-slate-200 ' /> : <IoIosArrowForward className='lg:hover:bg-slate-200 lg:bg-slate-300 lg:rounded-full lg:text-[4rem] text-slate-900 text-3xl' onClick={() => setImageSlider(imageSlider + 1)} />}
    </div>
  )
}

export default ImageSlider
