import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { useWindowSize } from '../Functions'

const BlogDetailsModal = (props) => {
    const blog_details = useSelector(state => state.post.posts.posts)
    const [selectedImage, setSelectedImage] = useState(0)
    const listOfImages = blog_details.find(b => b.id === props.clickedPost).images
    const [size , _] = useWindowSize()
    console.log(listOfImages.length)
    console.log(listOfImages[3])
    console.log(selectedImage)
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () =>
          document.body.style.overflow = 'unset';
      }, [])
    return (
        <div className=' h-screen w-full fixed top-0 right-0 animate-fade-in-down lg:flex lg:items-center lg:justify-center  ' style={{ "backgroundColor": "rgba(0,0,0,0.70)" }}>

            <div className='lg:w-2/3 lg:h-4/5 bg-transparent p-4 flex flex-col lg:items-center md:flex md:justify-center md:items-center md:h-4/5  rounded-md md:w-full'>
              

                        <div className='flex flex-row  gap-4 justify-center items-center lg:w-3/4 md:w-2/3 md:flex md:justify-center md:items-center '>
                           {size.width < 500 ? null :  <div className='rounded-full hover:bg-slate-200 cursor-pointer md:mt-[10rem] mt-[20rem] lg:mt-0'>
                                {0 < selectedImage ?
                                    <div className='bg-slate-800 rounded-full p-2 lg:p-4 md:p-4 hover:bg-slate-700 border-none'>
                                        <IoIosArrowBack className='text-[2.5rem] text-white' onClick={() => setSelectedImage(selectedImage - 1)} />
                                    </div>

                                    : <div className='bg-slate-800 rounded-full p-2 lg:p-4 md:p-4 hover:bg-slate-700 cursor-not-allowed border-none'>
                                        <IoIosArrowBack className='text-[2.5rem] text-white ' />
                                    </div>}
                            </div>}
                            <img className='p-4 lg:h-[80vh]   lg:mt-0 md:h-[50vh] md:mt-[10rem] h-[50vh] mt-[10rem]' src={"https://static.legendsportmanagement.com/" + listOfImages[selectedImage].path} />
                            {size.width < 500 ? null : <div className='rounded-full hover:bg-slate-200 cursor-pointer md:mt-[10rem] mt-[20rem] lg:mt-0'>
                                {listOfImages.length - 1 <= selectedImage ?
                                    <div className='bg-slate-800 rounded-full p-2 lg:p-4 md:p-4 hover:bg-slate-700'>
                                        <IoIosArrowForward className='text-[2.5rem]   cursor-not-allowed text-white' />
                                    </div> :
                                    <div className='bg-slate-800 rounded-full p-2 lg:p-4 md:p-4 hover:bg-slate-700 '>
                                        <IoIosArrowForward className='text-[2.5rem] text-white ' onClick={() => setSelectedImage(selectedImage + 1)} />
                                    </div>}
                            </div>}

                        </div>
                          <div className='w-full flex flex-row  gap-2 justify-center items-center '>
                          {size.width < 500 ? 0 < selectedImage ?
                                    <div className='bg-slate-800 rounded-full p-2 lg:p-4 md:p-4 hover:bg-slate-700 border-none'>
                                        <IoIosArrowBack className='text-[2.5rem] text-white' onClick={() => setSelectedImage(selectedImage - 1)} />
                                    </div>

                                    : <div className='bg-slate-800 rounded-full p-2 lg:p-4 md:p-4 hover:bg-slate-700 cursor-not-allowed border-none'>
                                        <IoIosArrowBack className='text-[2.5rem] text-white ' />
                                    </div> : null}
                          
                  <h1 onClick={() => props.setBlogDetails(false)} className='text-white font-poppins cursor-pointer text-xl bg-slate-800 p-2 pr-4 pl-4 rounded-md transition-all hover:scale-110'>close</h1>
                  {size.width < 500 ? listOfImages.length - 1 <= selectedImage ?
                                    <div className='bg-slate-800 rounded-full p-2 lg:p-4 md:p-4 hover:bg-slate-700'>
                                        <IoIosArrowForward className='text-[2.5rem]   cursor-not-allowed text-white' />
                                    </div> :
                                    <div className='bg-slate-800 rounded-full p-2 lg:p-4 md:p-4 hover:bg-slate-700 '>
                                        <IoIosArrowForward className='text-[2.5rem] text-white ' onClick={() => setSelectedImage(selectedImage + 1)} />
                                    </div> : null}
                </div>
                
                
            </div>

        </div>
    )
}

export default BlogDetailsModal
