import React from 'react'

const Scouting = () => {
  return (
    <div className='scouting mb-10 w-full lg:flex lg:flex-col lg:justify-center lg:items-center'>
        <p className='font-poppins p-2 text-justify lg:max-w-[1200px] lg:text-justify lg:text-lg'>Welcome to Legends Sport, where we believe in nurturing the talents of up-and-coming football players during the most crucial phase of their professional careers. Our highly qualified and experienced scouts accompany not only the players but also their parents through this journey, providing them with the support and guidance they need to succeed.</p>
        <p className='font-poppins p-2 text-justify lg:max-w-[1200px] lg:text-justify lg:text-lg'>
Our scouts are not only sports scientists but also keen observers with a sharp instinct for identifying talent. They regularly observe your games and training sessions, analyzing your potential and identifying your strengths and areas for improvement. With a focus on constructive criticism and motivation, our scouts help you develop your skills and improve your game.</p>
        <p className='font-poppins p-2 text-justify lg:max-w-[1200px] lg:text-justify lg:text-lg'>At Legends Sport, we understand that appraising talented players is only half the battle. That's why our scouts are also skilled at intensively swapping information, providing you with valuable insights into the world of football and helping you navigate the often complex and challenging landscape.</p>
        <p className='font-poppins p-2 text-justify lg:max-w-[1200px] lg:text-justify lg:text-lg'>Join us at Legends Sport and let our scouts help you realize your full potential as a football player. With our guidance and support, you can be confident in your abilities and take your career to new heights.</p>
    </div>
  )
}

export default Scouting