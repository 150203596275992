import React from 'react'

const Footer = () => {
  return (
    <div className='overflow-x-hidden absolute bottom-0  w-full  gap-1  flex flex-row  items-center justify-center'>
        <div className='flex flex-row items-center'>
            <h1 className='text-white font-raleway  text-[0.7rem] text-center lg:text-[1rem]'>ALL RIGHTS RESERVED 2023</h1>
        </div>
        <h1 className='text-white font-raleway p-1 text-[0.7rem] text-center lg:text-[1rem]'> &copy;  </h1>
    </div>
  )
}

export default Footer
