import React from 'react'

const Confirm = () => {
  return (
    <div className='bg-gradient-to-b from-slate-900 to-blue-900 w-screen h-screen flex flex-col justify-center items-center'>
      <div class="w-1/3 h-2/3   bg-white bg-opacity-30  rounded drop-shadow-lg  flex flex-col gap-4 items-center " >
        <div className='flex flex-row items-center gap-4 h-1/2'>
        <img src="https://legendsportmanagement.com/logo.png" className='rounded-full h-[4rem] w-[4rem]' />
        <h1 className='text-white font-poppins text-3xl'>Legends Sport Agency</h1>
        </div>
        <div className='flex flex-col gap-2 h-1/2 w-full items-center'>
          <input className='bg-transparent border-b-[0.1rem] p-2 outline-none text-xl w-2/3 text-white placeholder:text-white placeholder:font-poppins' type="text" placeholder="code" />
          
          <div className='flex flex-col gap-2 p-4 w-full justify-center items-center'>
          <button className='bg-white pl-4 pr-4 p-2 font-poppins text-lg w-1/5 rounded-md hover:bg-slate-400 hover:scale-110 hover:text-white '>CONFIRM</button>
          
          </div>
        </div>
      </div>
    </div>
  )
}

export default Confirm
