import React from 'react'

const NotFound = () => {
  return (
    <div className='bg-gradient-to-b from-slate-900 to-blue-900 w-screen h-screen flex flex-col justify-center items-center'>
      <div class="w-1/5 h-1/5   bg-white bg-opacity-30  rounded drop-shadow-lg  flex flex-row gap-4 items-center  justify-center" >
        
        <h1 className='font-poppins text-3xl text-white'>404 PAGE NOT FOUND</h1>
        <img src="https://legendsportmanagement.com/logo.png" className='rounded-full h-[2rem] w-[2rem] animate-bounce' />
        
  
      </div>
    </div>
  )
}

export default NotFound
