import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from '../Redux/Users/Slice';

const NavMenu = ({setExpandedMenu}) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () =>
            document.body.style.overflow = 'unset';
    }, [])

    const user_info = useSelector(state => state.user.user.current)
    console.log(user_info)
    const dispatch = useDispatch()
    const handleLogout = (e) => {
      e.preventDefault()
      dispatch(logout())
     
    }

    
    
    return (
        <div className='h-screen fixed top-[4.7rem] right-0   font-poppins text-slate-900 transition  animate-fade-in-down' style={{ "backgroundColor": "rgba(0,0,0,0.80)" }}>
            {/* <h1  className='bg-slate-50 w-screen text-center p-4 text-xl'>Home</h1> */}
            <h1 onClick={() => {setExpandedMenu(false) ;document.getElementById("services").scrollIntoView({behavior : 'smooth'})}} className='bg-slate-50 w-screen text-center p-4 text-xl'>Services</h1>
            <h1 onClick={() => {setExpandedMenu(false); document.getElementById("scouting").scrollIntoView({behavior : 'smooth'});}} className='bg-slate-50 w-screen text-center p-4 text-xl'>Scouting</h1>
            <h1 onClick={() => {setExpandedMenu(false); document.getElementById("brokerage").scrollIntoView({behavior : 'smooth'});}} className='bg-slate-50 w-screen text-center p-4 text-xl'>Brokerage</h1>
            <h1 onClick={() => {setExpandedMenu(false); document.getElementById("marketing").scrollIntoView({behavior : 'smooth'});}} className='bg-slate-50 w-screen text-center p-4 text-xl'>Marketing</h1>
            <h1 onClick={() => {setExpandedMenu(false); document.getElementById("contact").scrollIntoView({behavior : 'smooth'});}} className='bg-slate-50 w-screen text-center p-4 text-xl'>Contact</h1>
            <NavLink to="/blog"><h1 className='bg-slate-50 w-screen text-center p-4 text-xl'>Blog</h1></NavLink>
           {user_info != null ?  <h1 onClick={e => handleLogout(e)} className='bg-slate-50 w-screen text-center p-4 text-xl'>Logout</h1> : <NavLink to="/login"> <h1 className='bg-slate-50 w-screen text-center p-4 text-xl'>Login</h1></NavLink>}
        </div>
    )
    }

export default NavMenu