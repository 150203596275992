import React, { useState } from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { useWindowSize } from '../Functions'
import ImageSlider from './ImageSlider'

const Services = () => {

  const [size, _] = useWindowSize()
  const images = ["https://legendsportmanagement.com/slider1.jpeg", "https://legendsportmanagement.com/slider2.jpg", "https://legendsportmanagement.com/slider1.jpeg"]

  return (
    <div className='services w-full mb-10 lg:flex lg:flex-col lg:justify-center lg:items-center bg-neutral-100'>
      <p className='font-poppins p-2  text-justify lg:max-w-[1200px] lg:text-justify  lg:text-lg'>
        Welcome to Legends Sport, a rising star in the world of football management. We understand the demanding and time-consuming nature of being a professional football player, and our goal is to support our players in all aspects of their lives so they can focus on their careers.

      </p>
      <p className='font-poppins p-2 text-justify lg:max-w-[1200px] lg:text-justify  lg:text-lg'>
        Our team is passionate, knowledgeable, and dedicated to ensuring that every athlete we work with feels comfortable and successful. We specialize in training and match analysis, as well as video analysis of games and daily market observations. In addition, we offer our players access to a professional network that includes experts in medicine, media, industry, and economy.
      </p>

      <p className='font-poppins p-2 text-justify lg:max-w-[1200px] lg:text-justify  lg:text-lg'>
        We know that finding services like accommodation, tax consultants, and commercial partners can be a hassle and take up precious time that athletes simply can't spare. That's why we take care of these tasks for our players, so they can focus on playing their best on the field.
      </p>
      <p className='font-poppins p-2 text-justify lg:max-w-[1200px] lg:text-justify  lg:text-lg'>
        Our top priority is our players' satisfaction, and we pride ourselves on quickly resolving any problems that may arise. We believe that every athlete deserves to go to bed feeling content and supported, no matter what challenges they may face during the day.
      </p>
      <p className='font-poppins p-2 text-justify lg:max-w-[1200px] lg:text-justify  lg:text-lg'>At Legends Sport, we are committed to providing our players with the support they need to succeed both on and off the field. Join us and experience the difference of having a dedicated team of professionals by your side.</p>
      {size.width < 1000 ?
        <ImageSlider />
        :
        <div className='flex flex-row gap-2 w-full justify-center p-10 bg-neutral-100 '>
          {images.map(img => <img className='w-1/5 shadow-md' src={img} />)}
        </div>

      }
    </div>
  )
}

export default Services
