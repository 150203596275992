import { insertInHash, removeFromHash } from "../../Common/functions"
import { createHandler, buildFromCreator } from "../ReduxTemplates"
import { PostState as SliceInterface } from "./Interface"


export const PostCreater = {
    createPost : createHandler({
        name: "post/create",
        method: "POST",
        route: "/posts",
        skipAuth: false,
        key: "post",
        pending: (state:SliceInterface , action) =>{
            state.posts.createStatus = "pending"
            state.posts.createMessage = "Loading post, please wait.."
            return state
        },
        fulfilled: (state:SliceInterface , action) =>{
            state.posts.createStatus = "fulfilled"
            state.posts.createMessage = "Post created"
            state.posts.posts = [...state.posts.posts , action.payload.post]

            return state
            
        },
        rejected: (state:SliceInterface , action) =>{
            state.posts.createStatus = "rejected"
            state.posts.createMessage = action.payload.msg
            return state

            
        }

    }),
    readPost : createHandler({
        name: "post/read",
        method: "GET",
        route: "/posts",
        skipAuth: false,
        key: "",
        pending: (state:SliceInterface , action) =>{
            state.posts.readStatus = "pending"
            state.posts.readMessage = "Loading post, please wait.."
            return state
        },
        fulfilled: (state:SliceInterface , action) =>{
            state.posts.readStatus = "fulfilled"
            state.posts.readMessage = "Post created"

            if (action.payload?.post !== undefined) state.posts.posts = [...state.posts.posts , action.payload.post]
            else state.posts.posts = action.payload.AllPosts
 
            return state
            
        },
        rejected: (state:SliceInterface , action) =>{
            state.posts.readStatus = "rejected"
            state.posts.readMessage = action.payload.msg
            return state

            
        }
    }),
    updatePost : createHandler({
        name: "post/update",
        method: "PUT",
        route: "/posts",
        skipAuth: false,
        key: "post",
        pending: (state:SliceInterface , action) =>{
            state.posts.updateStatus = "pending"
            state.posts.updateMessage = "Loading post, please wait.."
            return state
        },
        fulfilled: (state:SliceInterface , action) =>{
            state.posts.updateStatus = "fulfilled"
            state.posts.updateMessage = "Post created"
            state.posts.posts = [
                ...state.posts.posts.filter(p => p.id !== action.payload.post.id),
                action.payload.post
            ]

            return state
            
        },
        rejected: (state:SliceInterface , action) =>{
            state.posts.updateStatus = "rejected"
            state.posts.updateMessage = action.payload.msg
            return state

            
        }
    })
}

export const [PostActions, PostReducers] = buildFromCreator(PostCreater)