import React from 'react'

const LogoFixed = () => {
  return (
    <div className='animate-pulse overflow-x-hidden absolute top-[20rem] right-0  w-full  gap-1  flex flex-row  items-center justify-center'>
        <img src="https://legendsportmanagement.com/logo-gold.png" className='w-2/6 z-30' />
     </div>
  )
}

export default LogoFixed
