import React ,{ useEffect, useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai';

const DeleteModal = ({setDeleteModal,handleDelete}) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () =>
          document.body.style.overflow = 'unset';
      }, [])
  return (
    <div className=' h-screen w-full fixed mx-auto animate-fade-in-down lg:flex lg:items-center lg:justify-center md:flex  md:justify-center   md:items-center' style={{ "backgroundColor": "rgba(0,0,0,0.50)" }}>
      
    <div className='bg-neutral-100 flex flex-col gap-2 p-8 justify-center items-center rounded-sm shadow-lg  lg:w-1/4 lg:h-1/4 md:w-2/3  md:h-1/2'>
     <AiOutlineDelete className='text-red-500 text-[4rem]'/>
     <h1 className='text-slate-500 font-poppins'>Do you really want to delete this post?</h1>
    <div className='flex flex-row w-full gap-4 p-4'>
    <button onClick={() => setDeleteModal(false)} className='font-poppins text-white w-1/2 bg-slate-400 pr-4 pl-4 p-2 transition-all hover:scale-110 rounded-md shadow-md mb-2'>cancel</button>
     <button onClick={e => handleDelete(e)} className='font-poppins text-white w-1/2 bg-red-500 pr-4 pl-4 p-2 transition-all hover:scale-110 rounded-md shadow-md mb-2'>delete</button>
    </div>
    </div>
  </div>
  )
}

export default DeleteModal