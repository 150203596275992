import React, { Fragment, useEffect, useState } from 'react'
import { AiOutlineClose, AiOutlineCloseSquare } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { PostActions } from '../Redux/Posts/Actions'
import {IoMdImages } from 'react-icons/io'
import { useWindowSize } from '../Functions'

const PostModal = (props) => {
  const dispatch = useDispatch()
  const [images, setImages] = useState([null, null, null, null])
  const [text, setText] = useState("")
  const [idx, setIdx] = useState(null)
  const [size , _] = useWindowSize()
  const UserReducer = useSelector(state => state.user)
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () =>
      document.body.style.overflow = 'unset';
  }, [])
  const handleFileSelect = (e, index) => {
    setIdx(index)
    e.target.nextSibling.click()
  }
  const handleFileChange = e => {
    const file = e.target.files[0]
    const imgs = [...images]
    imgs[idx] = file
    setImages(imgs)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const data = new FormData()
    data.append("post_text", text)
    data.append("user_id", UserReducer.user.current.id)
    for(let i = 0; i < 4; i++) {
      if (images[i] !== null) data.append("iamge_"+i, images[i])
    }
    dispatch(PostActions.createPost({ "post" : data }))    
  }
  const added_class = "w-1/4 h-[4rem] bg-emerald-400 text-center text-[2.8rem] hover:opacity-50"
  const reg_class = "w-1/4 h-[4rem] bg-slate-300 text-center text-[2.8rem] hover:opacity-50"

  return (
    <div className=' h-screen w-full fixed top-0 right-0 animate-fade-in-down lg:flex lg:items-center lg:justify-center md:flex  md:justify-center   md:items-center' style={{ "backgroundColor": "rgba(0,0,0,0.50)" }}>
      
      <div className='bg-neutral-100 flex flex-col gap-2 p-8  lg:w-1/3 md:w-2/3  md:h-1/2'>
      <div className='w-full flex flex-row justify-end text-xl p-2 '>
      <AiOutlineClose onClick={() => props.setPostModal(false)} className='text-black cursor-pointer' />
      </div>
        <h1 className='text-black font-poppins p-2 text-2xl'>Create new blog</h1>
        <textarea value={text} onChange={e => setText(e.target.value)} type="text" placeholder="text" className='w-full p-2 outline-none min-h-[3rem] max-h-[13rem] text-black' />
        <div className=' w-full flex flex-row gap-1 '>
          {[0, 1, 2, 3].map(idx => (
            <Fragment>
              <h1 onClick={e => handleFileSelect(e, idx)} className={images[idx] !== null ? added_class : reg_class}>{images[idx] !== null ? "✓": "+"}</h1>
              <input type="file" multiple="false" accept="image/*" onChange={e => handleFileChange(e)} className="hidden" />
            </Fragment>
          ))}
        </div>
        <button className='bg-slate-900 p-2 hover:bg-slate-700' onClick={e => handleSubmit(e)}>POST</button>
      </div>
    </div>
  )
}

export default PostModal