import React from 'react'

const Marketing = () => {
  return (
    <div className='mb-10 marketing w-full lg:flex lg:flex-col lg:justify-center lg:items-center'>
        {/* <p className='font-poppins p-2 text-center lg:max-w-[1200px] lg:text-center lg:text-lg'>Take a closer look into our amazing team. We won’t bite.</p> */}
        <p className='font-poppins p-2 text-center lg:max-w-[1200px] lg:text-center lg:text-lg'>We Fuel Growth</p>
        <p className='font-poppins p-2 text-justify lg:max-w-[1200px] lg:text-justify lg:text-lg'>Agency Athlete will build your brand for the long haul. The average pro athlete career lasts only 3.8 years. We prepare our clients for their post-career from the moment they sign with us. Successful marketing takes a sustained effort by an experienced team. We are dedicated to helping our clients be successful both on and off the field. This is where Agency Athlete has set itself apart from the competition.</p>
        <p className='font-poppins p-2 text-center lg:max-w-[1200px] lg:text-center lg:text-lg'>Public Relations</p>
        <p className='font-poppins p-2 text-justify lg:max-w-[1200px] lg:text-justify lg:text-lg'>Agency Athlete offers an integrated public relations approach that increases media visibility by generating brand buzz. We commence the process with an in-depth brand analysis report that offers personal strategies in utilizing an athlete’s talent in the best way. The findings in this report provide us insight on how best to position your brand, leverage your star-power and create media opportunities. Our public relations solutions help take your game to the next level while having a dedicated team to back you up.</p>
        <p className='font-poppins p-2 text-center lg:max-w-[1200px] lg:text-center lg:text-lg'>Social Media</p>
        <p className='font-poppins p-2 text-justify lg:max-w-[1200px] lg:text-justify lg:text-lg'>Agency Athlete offers a range of social media services, including strategic planning, community management, digital marketing, brand monitoring and optimization. Social media is now a critical component to an athlete’s overall brand. Our team of social media consultants will work with you to develop a custom strategy that fits your vision. Our integrated approach is designed to leverage social media in order to increase visibility, engage your fan base and generate new business opportunities. Agency Athlete is an expert in utilizing social media strategies that leverage popular social networks in order to organically create media buzz for an athlete’s brand.</p>
    </div>
  )
}

export default Marketing