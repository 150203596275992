import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Link, Navigate } from 'react-router-dom'
import Footer from '../MainPage/Footer'
import { UserActions } from '../Redux/Users/Actions'

const Login = () => {
  const user_login_info = useSelector(state => state.user.user)
  // console.log(user_login_info)
  const [slogin , setLogin] = useState({
    email : "",
    password : ""
  })
  const dispatch = useDispatch()
  const handleLogin = (e) => {
    e.preventDefault()
    const form = new FormData()
    form.append("email" , slogin["email"])
    form.append("password" , slogin["password"])
    console.log(form)
    dispatch(UserActions.login({user : form}))

  }
  const handleChange = (e) => {
      setLogin({...slogin , [e.target.name] : e.target.value})
  }
  // console.log(slogin["email"])
  return (
    <form onSubmit={e => handleLogin(e)} className='bg-gradient-to-b from-slate-900 to-neutral-900 w-screen h-screen flex flex-col justify-center items-center'>
      <div class="lg:w-1/3 lg:h-2/3 w-3/4 h-2/3   bg-white bg-opacity-30  rounded drop-shadow-lg  flex flex-col gap-4 items-center " >
        <div className='lg:flex lg:flex-row lg:items-center lg:gap-4 lg:h-1/2 flex flex-col items-center h-1/3 justify-center gap-2'>
        <img src="https://legendsportmanagement.com/logo-gold.png" className='rounded-full h-[140px]' />
        </div>
        <div className='flex flex-col gap-2 h-1/2 w-full items-center'>
          <input onChange={e => handleChange(e)} value={slogin["email"] || ""} name='email' className='bg-transparent border-b-[0.1rem] p-2 outline-none text-xl w-2/3 text-white placeholder:text-white placeholder:font-poppins' type="text" placeholder="email" />
          <input onChange={e => handleChange(e)} value={slogin["password"]} name='password' type="password" className='bg-transparent border-b-[0.1rem] p-2 outline-none text-xl w-2/3 text-white placeholder:text-white placeholder:font-poppins'  placeholder="password" />
          <div className='flex flex-col gap-2 p-4 w-full justify-center items-center'>
          
          <button onClick={e => handleLogin(e)} className='bg-white lg:pl-4 lg:pr-4 p-2 font-poppins text-lg lg:w-1/5 w-3/4 md:w-1/2 rounded-md hover:bg-slate-400 hover:scale-110 hover:text-white '>LOGIN</button>
          {user_login_info.loginMessage === "Login success." ? <Navigate to="/" /> : null}
          {/* <h1 className='text-white font-poppins text-xl'>or</h1>
          <Link to="/register" className='bg-white lg:pl-4 lg:pr-4 p-2 font-poppins text-lg lg:w-1/5 w-1/2 rounded-md hover:bg-slate-400 hover:scale-110 hover:text-white'><button  className='w-full  text-center'>REGISTER</button></Link> */}
          {user_login_info.loginStatus === "rejected" ? <h1 className='text-red-500 font-bold font-poppins text-sm lg:text-lg p-2'>Incorrect email or password!</h1> : null}
          {user_login_info.token != "" ? <h1 className='text-emerald-500 font-bold font-poppins text-sm lg:text-lg p-2'>Login success!</h1> : null}
          </div>
        </div>
      </div>
      <Footer />
    </form>
  )
}

export default Login
